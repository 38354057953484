<template>
    <el-menu-item v-if="isLeaf" :index="`${menu.path || menu.id}`" :route="{ path: menu.path }">
        <span v-if="isSecond && menu.name != '技术经理人'">
            <span class="isSecond_img"></span>
            <img src="../assets/png-xuanzhong@3x.png" alt="" class="isSecond_img_con">
            {{ menu.name }}
        </span>
        <span v-else slot="title" class="isSecond_title">{{ menu.name }}</span>
    </el-menu-item>
    <el-submenu v-else :index="`${menu.path || menu.id}`">
        <template slot="title">
            <span slot="title">{{ menu.name }}</span>
        </template>
        <sys-menu v-for="item in menu.children" isSecond :menu="item" :key="item.id"></sys-menu>
    </el-submenu>
</template>
<script>
export default {
    name: 'SysMenu',
    props: {
        menu: {
            type: Object,
            required: true
        },
        isSecond: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {};
    },
    methods: {
        click(e) {
            console.log(e);
        }
    },
    computed: {
        isLeaf() {
            return !(this.menu.children instanceof Array && this.menu.children.length);
        }
    }
};
</script>
<style lang="less" scoped>
.isSecond_img {
    width: 14px;
    height: 14px;
    border-radius: 2px;
    border: 1px solid #d5d8de;
    display: inline-block;
    box-sizing: border-box;
    margin-right: 6px;
    margin-top: -3px;
}
.isSecond_img_con {
    width: 14px !important;
    height: 14px !important;
    margin-top: -3px;
    margin-right: 6px;
}
</style>