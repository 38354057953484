<template>
        <div class="service">
            <div class="service-img">
                <img :src="info.icon" alt="" />
                <div class="text">{{ info.title }}</div>
            </div>

            <div class="service-hover">
                <div class="name">{{ info.title }}</div>
                <div class="content" v-html="content"></div>
            </div>
        </div>
</template>

<script>
export default {
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    computed: {
        content() {
            return this.info.content.replace(/\n/g, '<br/>');
        }
    }
};
</script>

<style lang="less" scoped>
.service {
    width: 300px !important;
    // width: 300px;
    height: 128px;
    // height: 340px;
    overflow: hidden;
    border-radius: 2px;
    position: relative;
    border: 1px solid rgba(56,85,142,0.04);
    box-sizing: border-box;
    margin: 10px;
    
    .service-img {
        box-sizing: border-box;
        background: #FBFBFB;
        padding-left: 50px;
        padding-right: 50px;
        box-sizing: border-box;
        // border-bottom: 6px solid @prim;
        height: 100%;
        .flex();
        // justify-content: center;
        img {
            width: 56px;
            height: 56px;
        }

        .text {
            font-size: 16px;
            font-weight: bold;
            color: #000000;
            line-height: 24px;
            margin-left: 12px;
        }
    }

    .service-hover {
        background: #008a69;
        height: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        padding: 0 20px;
        transition: top ease-in-out 0.3s;
        .flex-col();

        .name {
            font-size: 16px;
            font-weight: bold;
            color: #ffffff;
            line-height: 24px;
            text-align: center;
            padding: 10px 0 10px;
            border-bottom: 1px solid #fff;
        }

        .content {
            font-size: 14px;
            color: #ffffff;
            line-height: 22px;
            text-align: center;
            padding: 16px 0 16px;
            flex-grow: 1;
            overflow: auto;

            &::-webkit-scrollbar-thumb {
                background: lighten(@warn, 10%);
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: darken(@warn, 10%);
            }
        }
    }

    &:hover {
        .service-hover {
            top: 0;
        }
    }
}
</style>
