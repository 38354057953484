<template>
    <!-- <div class="container"> -->
    <!-- <banner></banner> -->
    <!-- <div class="center-content" style="padding-top: 60px"> -->
    <bread-page>
        <menu-page>
            <div class="menu-content" slot="menuList">
                <div class="menu-left-con">
                    <img src="../../assets/gongxu_icon_xuqiuxinxi@3x.png" alt="" />
                    <div class="menu-left-con_title">知产运营服务</div>
                </div>
                <el-menu :default-active="active" @select="selectEvent" class="menu-content" unique-opened>
                    <sys-menu v-for="item in menus" :menu="item" :key="item.id"></sys-menu>
                </el-menu>
            </div>

            <div class="main">
                <div class="list">
                    <service-detail-grid v-for="(item, index) in list" :info="item" :key="index"></service-detail-grid>
                </div>

                <div class="btn">
                    <el-button type="primary" class="icon-button" @click.prevent="connectKefu" size="small">
                        <i class="iconfont iconfont-nav_icon_xiaoxi1"></i>
                        咨询
                    </el-button>
                    <el-button type="primary" @click.prevent="postMessage" class="icon-button" size="small">
                        <i class="iconfont iconfont-nav_icon_xiaoxi"></i>留言
                    </el-button>
                </div>
            </div>
        </menu-page>
    </bread-page>
    <!-- </div>
    </div> -->
</template>

<script>
import Banner from '../../components/Banner.vue';
import MenuPage from '../../components/page/MenuPage.vue';
import SysMenu from '../../components/SysMenu.vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import ServiceDetailGrid from '../../components/list/ServiceDetailGrid.vue';
import comEvent from '../../mixins/comEvent';
import BreadPage from '../../components/page/BreadPage.vue';
export default {
    components: { MenuPage, Banner, SysMenu, Swiper, SwiperSlide, ServiceDetailGrid, BreadPage },
    data() {
        return {
            active: '',
            menus: [],
            info: {},
            options: {},
            empty: false,
            loading: true,
            list: [],
            colLength: 3
        };
    },
    mixins: [comEvent],
    watch: {
        $route() {
            if (this.$route.query.navigationId) {
                this.active = this.$route.query.navigationId;
                this.getContent();
            }
        }
    },
    mounted() {
        this.$http
            .post('navigation/backAll', {
                type: 'AGENT'
            })
            .then(res => {
                let defaultList = [1014, 127];
                this.menus = res.map(item => {
                    return {
                        ...item,
                        children: item.children.map(child => {
                            return {
                                ...child,
                                preId: defaultList.includes(child.parent) ? res[0].children[0].id : item.children[0].id
                            };
                        })
                    };
                });
                // this.scrollTo(true);
                if (this.$route.query.navigationId) {
                    this.active = this.$route.query.navigationId;
                    this.getContent();
                }
            });

        this.$EventBus.$on('noActive', () => {
            let menus = [...this.menus]
                .map(item => {
                    return [item, ...item.children];
                })
                .flat();
            // console.log(menus);
            let menu = menus.find(item => {
                return `${item.id}` === this.active;
            });
            this.$EventBus.$emit('setPrePath', `/agent?navigationId=${menu.preId}`);
        });
    },
    methods: {
        scrollTo(isFirst = false) {
            this.$nextTick(() => {
                let top = window.innerWidth * 0.2625 > 341 ? window.innerWidth * 0.2625 : 341;
                setTimeout(
                    () => {
                        window.scrollTo({
                            top: top + 200
                        });
                    },
                    isFirst ? 500 : 0
                );
            });
        },
        selectEvent(index) {
            this.$router.replace({
                query: {
                    navigationId: index
                }
            });
        },
        getContent() {
            this.loading = true;
            this.$http.get('/serviceDetail/getByNavigationId/' + this.active).then(res => {
                this.list = res;
                this.empty = res.length === 0;
                this.loading = false;
                if (res.length === 4) {
                    this.colLength = 4;
                } else {
                    this.colLength = 3;
                }
            });
        }
    },
    beforeDestroy() {
        this.$EventBus.$off('noActive');
    }
};
</script>

<style lang="less" scoped>
.container {
    background-color: @bg;
}
.menu-left-con {
    height: 60px;
    background: #01a041;
    display: flex;
    padding-left: 34px;
    align-items: center;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #ffffff;
    .menu-left-con_title{
        font-size: 16px;
    }
    img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }
}
.main {
    // min-height: 1080px;
    flex-grow: 1;
    .flex-col();
}

.menu-content {
    min-height: 800px;
    background-color: #fff;
}
.detail {
    padding: 20px;
    background-color: #fff;
    min-height: 50%;
    box-sizing: border-box;
}

.process {
    margin-top: 42px;
    width: 100%;
    display: block;
    height: auto;
}

.empty {
    padding: 100px 0;
    .flex-col();
    align-items: center;
    i {
        font-size: 100px;
        color: #939599;
    }

    .empty-text {
        font-size: 14px;
        color: #939599;
        margin-top: 20px;
    }
}

.main {
    background-color: #fff;
    .icon-button {
        width: 134px;
        height: 36px;
        color: #01a041;
        justify-content: center;
        border-radius: 2px;
        border: 1px solid #01a041;
        background: #FFFFFF;
    }
    .btn {
        padding: 60px 0 60px;
        .flex();
        justify-content: center;
    }
}

.list {
    background: #fff;
    padding: 10px;
    .flex();
    flex-wrap: wrap;
    .service {
        width: calc(33% - 20px);
    }

    &.col4 {
        .service {
            width: calc(25% - 20px);

            /deep/ .service-img {
                flex-direction: column;

                .text {
                    margin-left: 0;
                    margin-top: 14px;
                }
            }
        }
    }
}
/deep/ .el-menu-item {
    // text-align: center;
    // padding-right: 60px;
    padding-left: 68px !important;
}
/deep/ .el-menu > .el-menu-item {
    &.is-active {
        background: linear-gradient(90deg, rgba(1, 160, 65, 0.1) 0%, rgba(255, 255, 255 , 0.1) 100%);
        color: #01a041;
    }
}
&.is-active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 1px;
    background-color: #01a041;
}
</style>
