<template>
    <el-container direction="horizontal">
        <el-aside width="200px">
            <slot name="menuList"></slot>
        </el-aside>
        <el-container direction="vertical" class="main">
            <el-main style="padding: 0">
                <slot></slot>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.main {
    margin-left: 20px;
    .el-main {
        padding: 0;
        .flex-col();
    }
}

.menu-content {
    min-height: 800px;
    background-color: #fff;
}

/deep/ .el-menu > .el-menu-item {
    // text-align: center;
    height: 60px !important;
    line-height: 60px !important;
    margin-top: 10px;
    // margin-bottom: 10px;
    &.is-active {
        // background: linear-gradient(90deg, #01a041 0%, #ffffff 100%);
        // background: linear-gradient(90deg, #01a041 0%, #ffffff 100%);
        // box-shadow: inset 2px 0px 0px 0px #01a041;
        // color: #01a041;
        font-weight: 400;
        position: relative;
    }
    &:hover {
        background-color: #e4e4e4;
        // background-color: #e4e4e4 !important;
        color: #000000;
        // font-weight: bold;
    }
}

/deep/ .el-menu {
    // padding-top: 10px;
    .el-submenu {
        .el-submenu {
            .el-submenu__title {
                padding-left: 60px !important;
            }
        }

        .el-menu--inline {
            background-color: #fff;

            .el-menu-item {
                height: 60px;
                // height: auto !important;
                line-height: 60px !important;
                // padding: 20px 20px 20px 80px !important;
                span {
                    white-space: pre-wrap;
                }
                &.is-active {
                    background: #ffffff;
                    color: #01a041;
                }
            }
        }

        .el-submenu__title {
            padding-left: 68px !important;
            height: 60px !important;
            line-height: 60px !important;

            &:hover {
                background-color: #e4e4e4;
                color: #000000;
                // font-weight: bold;
            }
            i {
                font-weight: bold;
            }
        }

        &.is-active > .el-submenu__title {
            background: linear-gradient(90deg, rgba(1, 160, 65, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%);
            color: #01a041;
            i {
                color: #01a041;
            }
        }
        //     &.is-active > .el-submenu__title::after {
        //     content: '';
        //     position: absolute;
        //     bottom: 0;
        //     left: 0;
        //     height: 60px;
        //     width: 1px;
        //     background-color: red;
        // }
    }
}
</style>
